/* eslint-disable jsx-a11y/anchor-is-valid */

import "typeface-roboto";
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import { withStyles } from "@material-ui/core/styles";

import Header from "../component/header";
import JobCard from "../component/jobCard";
import TrainingCard from "../component/trainingCard";
import AvatarImg from "../img/avatar.jpg";

import withRoot from "../withRoot";
import { jobs } from "../jobs";
import { trainings } from "../trainings";
import { self } from "../self";

const styles = ({ spacing, breakpoints }) => ({
  main: {
    position: "relative",
    marginTop: "-5rem",
    marginBottom: "5rem",
    marginLeft: "1rem",
    marginRight: "1rem",
    [breakpoints.up("md")]: {
      marginLeft: "5rem",
      marginRight: "5rem",
    },
    padding: spacing(2),
  },
  avatar: {
    transform: "translate3d(0, -50%, 0)",
    width: "140px",
    height: "140px",
  },
  bio: {
    maxWidth: "800px",
    marginLeft: "auto",
    marginRight: "auto",
  },
  bioDivider: {
    marginTop: spacing(2),
    marginBottom: spacing(2),
  },
  blocPart: {
    maxWidth: "100%",
    [breakpoints.up("md")]: {
      maxWidth: "1024px",
    },
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: "3rem",
  },
  OSCard: {
    height: "100%",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  subTitle: {
    marginBottom: "2rem",
    width: "100%",
  },
});

class Index extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Sébastien Viande - Developper FullStack</title>{" "}
          <meta
            name="description"
            content="Personal website, portfolio, blog."
          />
          <script type="application/ld+json">{JSON.stringify(self)}</script>
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="/apple-touch-icon.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/favicon-32x32.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/favicon-16x16.png"
          />
        </Helmet>
        <Header />
        <Paper id="main" className={classes.main}>
          <Grid container>
            <Grid item container justifyContent="center">
              <Avatar src={AvatarImg} className={classes.avatar} />
            </Grid>
            <Grid item style={{ width: "100%" }}>
              <Typography
                color="primary"
                align="center"
                variant="h2"
                component="h1"
                gutterBottom
              >
                Sebastien Viande
              </Typography>
              <Typography
                variant="h4"
                component="h2"
                align="center"
                color="textSecondary"
                gutterBottom
              >
                Developper FullStack
              </Typography>
              <Typography
                variant="body1"
                align="center"
                color="textSecondary"
                gutterBottom
                className={classes.bio}
              >
                Bonjour, j&#39;ai{" "}
                {new Date().getFullYear() - new Date("1989-04").getFullYear()}
                ans, je suis developpeur Web depuis 2010. Je suis passioné par
                le Web, fan de linux et du langage Go. Vous pouvez me retrouver
                sur le web via mon{" "}
                <a href="https://sebastienviande.name">site perso</a> ,{" "}
                <a href="https://github.com/sviande">GitHub</a>, ou{" "}
                <a href="https://twitter.com/sviande">Twitter</a>.
              </Typography>
            </Grid>
            <Divider className={classes.bioDivider} />
            <Grid
              item
              xs={12}
              container
              spacing={2}
              className={classes.blocPart}
            >
              <Grid item container spacing={1} direction="column">
                <Grid item>
                  <Typography
                    color="primary"
                    align="center"
                    variant="h2"
                    className={classes.subTitle}
                  >
                    Open Source
                  </Typography>
                </Grid>
                <Grid item container justifyContent="center" spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Card className={classes.OSCard}>
                      <CardContent>
                        <Typography
                          variant="h4"
                          color="textPrimary"
                          gutterBottom
                        >
                          NuxConfig (dotfiles)
                        </Typography>
                        <Typography color="textSecondary">
                          Partage de la configuration des outils installés sur
                          mes PC (Archlinux).
                        </Typography>
                        <Typography component="ul">
                          <li>NeoVim</li>
                          <li>Sway</li>
                          <li>Fish</li>
                          <li>Git</li>
                          <li>others...</li>
                        </Typography>
                      </CardContent>
                      <CardActions style={{ height: "3rem" }}>
                        <a
                          href="https://github.com/sviande/NuxConfig"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          Voir le github
                        </a>
                      </CardActions>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Card className={classes.OSCard}>
                      <CardContent>
                        <Typography
                          variant="h4"
                          color="textPrimary"
                          gutterBottom
                        >
                          Wallabag
                        </Typography>
                        <Typography color="textSecondary">
                          wallabag is a self hostable application for saving web
                          pages: Save and classify articles. Read them later.
                          Freely. https://wallabag.org
                        </Typography>
                        <Typography component="ul">
                          <li>Bug fix sur l&#039;API REST</li>
                          <li>
                            Ajout d&#39;un champs pour conserver la date
                            d&#39;archivage d&#39;un article.
                          </li>
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <a
                          href="https://github.com/wallabag/wallabag"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          Voir le github
                        </a>
                      </CardActions>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Card className={classes.OSCard}>
                      <CardContent>
                        <Typography
                          variant="h4"
                          color="textPrimary"
                          gutterBottom
                        >
                          UEFA bot
                        </Typography>
                        <Typography color="textSecondary">
                          UefaBot is a simple bot that send slack messages on
                          matchs events (live, goals, finished).
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <a
                          href="https://github.com/sviande/uefaBot"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          Voir le github
                        </a>
                      </CardActions>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Card className={classes.OSCard}>
                      <CardContent>
                        <Typography
                          variant="h4"
                          color="textPrimary"
                          gutterBottom
                        >
                          Github profile
                        </Typography>
                        <Typography color="textSecondary">
                          Page d'accueil de mon profile github où vous pouvez me
                          suivre et voir l'évolution de mes contributions sur
                          d'autres projets.
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <a
                          href="https://github.com/sviande"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          Voir le profile github
                        </a>
                      </CardActions>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              container
              spacing={2}
              className={classes.blocPart}
            >
              <Grid item container spacing={1} direction="column">
                <Grid item>
                  <Typography
                    color="primary"
                    align="center"
                    variant="h2"
                    className={classes.subTitle}
                  >
                    Expériences professionelles
                  </Typography>
                </Grid>
                <Grid item container justifyContent="center" spacing={2}>
                  {jobs.map((job) => (
                    <Grid key={job.company.name} item xs={12}>
                      <JobCard job={job} />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
            <Grid item container spacing={2} className={classes.blocPart}>
              <Grid item container spacing={1} direction="column">
                <Grid item>
                  <Typography
                    align="center"
                    variant="h2"
                    color="primary"
                    className={classes.subTitle}
                  >
                    Formations / Diplômes
                  </Typography>
                </Grid>
                <Grid item container justifyContent="center" spacing={2}>
                  {trainings.map((training) => (
                    <Grid key={training.title} item xs={12} sm={6}>
                      <TrainingCard training={training} />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Fragment>
    );
  }
}

Index.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRoot(withStyles(styles)(Index));
