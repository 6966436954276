import React, { Fragment } from "react";

const trainings = [
  {
    company: {
      site: "https://ecole-ingenieurs.cesi.fr/",
      logo: "/training/cesi.jpg",
      name: "Cesi"
    },
    title: "Cesi",
    description: "Ecole d'ingénieur",
    body: (
      <Fragment>
        <p>Manager des systémes d&#039;informations (2012)</p>
        <p>Responsable d&#039;ingénierie logiciel (2011).</p>
        <p>Analyste programmeur (2009)</p>
      </Fragment>
    ),
    startDate: "2007",
    endDate: "2012"
  },
  {
    company: {
      site: "http://www.zend.com/en/yellow-pages/ZEND023973",
      logo: "/training/ZCPE.jpg",
      name: "Zend PHP"
    },
    title: "ZCE",
    description: "Zend Certified Engineer",
    body: <p>Certification PHP 5.5</p>,
    startDate: "28 Novembre 2013"
  }
];

export { trainings };
