const self = {
  "@context": "http://schema.org",
  "@type": "Person",
  image: "avatar.jpg",
  jobTitle: "Developper FullStack",
  name: "Sébastien Viande",
  birthDate: "1989.04.09",
  url: "https://sebastienviande.name",
  sameAs: [
    "https://github.com/sviande",
    "http://twitter.com/sviande",
    "https://www.linkedin.com/in/s%C3%A9bastien-viande-3958b540/"
  ],
  worksFor: {
    "@type": "Corporation",
    name: "Capssion"
  },
  alumniOf: {
    "@type": "EducationalOrganization",
    url: "https://ecole-ingenieurs.cesi.fr/",
    name: "Cesi"
  }
};

export { self };
