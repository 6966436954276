import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";

const styles = ({ spacing }) => ({
  card: {
    display: "flex",
    padding: spacing(2),
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
  logo: {
    width: "5rem",
    height: "5rem",
    marginLeft: "auto",
    marginRight: "auto",
    backgroundSize: "contain",
  },
  corp: {
    textAlign: "center",
    display: "flex",
    justifyContent: "space-around",
    flexDirection: "column",
  },
});

function JobCard(props) {
  const { classes, job } = props;

  return (
    <Card className={classes.card}>
      <Grid container>
        <Grid item xs={12} sm={4} className={classes.corp}>
          <Typography color="primary" component="p" variant="h5">
            {job.title}
          </Typography>
          <div>
            <a href={job.company.site}>
              <CardMedia
                className={classes.logo}
                image={job.company.logo}
                title={`Logo ${job.company.name}`}
              />
              <span>{job.company.name}</span>
            </a>
            <br />
            <Typography variant="body1">
              {job.info}
              <br />
              {job.startDate}-{job.endDate}
            </Typography>
          </div>
        </Grid>
        <Grid xs={12} sm={8} item>
          <div className={classes.details}>
            <CardContent className={classes.content}>
              <Typography variant="h4" gutterBottom>
                {job.description}
              </Typography>
              <Typography component="div" variant="body1">
                {job.body}
              </Typography>
            </CardContent>
          </div>
        </Grid>
      </Grid>
    </Card>
  );
}

JobCard.propTypes = {
  classes: PropTypes.object.isRequired,
  job: PropTypes.object.isRequired,
};

export default withStyles(styles)(JobCard);
