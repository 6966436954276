import React, { Fragment } from "react";
import AvatarImg from "./img/avatar.jpg";
import Typography from "@material-ui/core/Typography";

const jobs = [
  {
    company: {
      site: "https://www.capssion.com",
      logo: "/corp/capssion-logo.svg",
      name: "Capssion",
    },
    title: "Développeur FullStack",
    info: "Télétravail",
    description:
      "Architecture/Développement d'une application Web et une application Mobile",
    body: (
      <Fragment>
        <Typography component="ul" variant="body1">
          <li>Développement d&#039;une application Web avec MeteorJS/React</li>
          <li>Développement d&#039;une application Mobile ReactNative</li>
          <li>Mis en place de jobs asynchrone SQS, Lambda</li>
          <li>Mis en place de tests Mocha/Chai</li>
          <li>Gestion des serveurs</li>
          <li>Mis en place d&#039;outils de monitoring</li>
        </Typography>
      </Fragment>
    ),
    startDate: "Mai 2018",
    endDate: "Aujourd'hui",
  },
  {
    company: {
      site: "https://www.satisfactory.fr",
      logo: "/corp/satisfactory-logo.png",
      name: "Satisfactory",
    },
    title: "Développeur FullStack",
    info: "Télétravail",
    description:
      "Développement d'une application Web pour le Feedback Management.",
    body: (
      <Fragment>
        <Typography component="ul" variant="body1">
          <li>
            Migration de l&#39;application PHP/HTML/Jquery vers PHP (API) /
            Angular 1
          </li>
          <li>
            Ajout de fonctionnalités PHP (Zend Framework 1, Symfony 3, MySQL,
            ElasticSearch, Angular 1)
          </li>
          <li>
            Amélioration du processus de développement (Jenkins, Gitlab, Docker,
            Behat)
          </li>
          <li>Gestions des serveurs (Ansible)</li>
          <li>
            Gestion d&#39;une équipe de développement (Mis en place d&#39;un
            environement agile)
          </li>
        </Typography>
      </Fragment>
    ),
    startDate: "Septembre 2014",
    endDate: "Février 2018",
  },
  {
    company: {
      site: "https://www.linkedin.com/company/wamigo",
      logo: "/corp/wamigo-logo.png",
      name: "Wamigo",
    },
    title: "Développeur FullStack",
    info: "Télétravail",
    description:
      "Développement d'une application Web permettant la gestion de communautés. ",
    body: (
      <Fragment>
        <Typography component="ul" variant="body1">
          <li>
            Développement service REST en PHP (Zend Framework, MySQL, tests
            unitaires)
          </li>
          <li>Développement de frontend (MarionnetteJS)</li>
        </Typography>
      </Fragment>
    ),
    startDate: "Mai 2014",
    endDate: "Septembre 2014",
  },
  {
    company: {
      site: "https://www.activisu.com/fr/",
      logo: "/corp/ivs-logo.png",
      name: "IVS",
    },
    title: "Développeur FullStack",
    description:
      "Développement Web et mobile d'applications pour le système d'information",
    body: (
      <Fragment>
        <details open>
          <summary>
            Développement d&#039;une application Web (CRM, Intranet) pour le
            système d’information.
          </summary>
          <div className="description">
            Amélioration du CRM pour les techniciens support et les commerciaux
            pour les deux entités de l&#039;entreprise.
          </div>
          <Typography component="ul" variant="body1">
            <li>
              Développement de fonctionnalités pour les CRM (PHP, MooTools,
              PostgreSQL)
            </li>
            <li>Développement PHP avec un framework maison (YKS)</li>
          </Typography>
        </details>
        <details open>
          <summary>
            Création d&#039;une application Android sur Samsung Galaxy Note 2/3,
            pour la rédaction des procès verbaux.
          </summary>
          <div className="description">
            L&#039;ancien processus de génération de procès verbaux devenu
            sources de nombreux problèmes et chronophage (envoi de fax,
            réception, gestion, tri).
            <br />
            Le nouveau processus permet la rédaction de procès verbaux avec le
            client, puis l&#039;envoi et l&#039;intégration dans le CRM depuis
            le smartphone.
            <br />
            L&#039;avantage de cette solution est de ne pas avoir de perte de
            donner et de les intégrer dans un workflow plus rapidement.
          </div>
          <Typography component="ul" variant="body1">
            <li>
              Gestion d&#039;une application avec gestion du stylet du téléphone
              (Java, HTML, JS)
            </li>
            <li>
              Lecture de document Word pour les transformer en formulaire web
              (PHP)
            </li>
          </Typography>
        </details>
      </Fragment>
    ),
    startDate: "Février 2012",
    endDate: "Mai 2014",
  },
  {
    company: {
      site: "https://sebastienviande.name",
      logo: AvatarImg,
      name: "Sébastien Viande",
    },
    title: "Développeur Web indépendant",
    description:
      "Développement d'une application Web (GED, Gestion fiches clients) ",
    body: (
      <Fragment>
        Création des modules de gestion des utilisateurs, intégration de
        documents depuis des sources extérieures, entre autres.
        <Typography component="ul" variant="body1">
          <li>Développement en PHP/HTML/Jquery</li>
        </Typography>
      </Fragment>
    ),
    startDate: "Février 2012",
    endDate: "Mai 2014",
  },
];

export { jobs };
